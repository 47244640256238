import * as React from 'react'
import { Trans } from '@lingui/macro'

import { Label } from '../../lib/components/icon'
import { Picture, PictureProps } from '../../lib/components/picture'

import css from './styles.css'

type Props = {
    format?: string
    alt: string
    image?: PictureProps['image']
    lazy?: boolean
    preload?: boolean
    height?: number
    width?: number
}

export function LabelThumbnail(props: Props): React.ReactElement {
    const { image, alt, lazy, preload, width = 150, height = 150 } = props

    if (image) {
        return (
            <div className={css.image}>
                <Picture image={image} lazy={lazy} alt={alt} preload={preload} width={width} height={height} />
            </div>
        )
    }

    return (
        <div className={css.fallback}>
            <Label style={{ width }} alt={alt}>
                <title>
                    <Trans>No image available; add an image</Trans>
                </title>
            </Label>
        </div>
    )
}
