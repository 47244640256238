import React, { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import { Section } from '../../lib/components/section'

import { LabelActionsFragment } from '../../api/types'
import { Link } from '../../lib/components/link'
import { ShortCode } from '../../lib/components/shortcode'
import { IdentifierType } from '../../lib/components/identifier'

import { LabelDataQuality } from '../data-quality'

import css from './styles.css'

export function LabelActions(props: LabelActionsFragment): ReactElement {
    const { discogsId, siteUrl, name, pendingSubmissionsCount, dataQuality, visibility } = props

    return (
        <Section
            id='actions'
            title={<LabelHeader discogsId={discogsId} />}
            altAction={<ShortCode type={IdentifierType.Label} discogsId={discogsId} className={css.shortcode} />}
            className={css.labelActionsContainer}
        >
            <Link href={`${siteUrl}/history#latest`}>
                <Trans>Edit Label</Trans>
            </Link>
            <br />

            <LabelDataQuality dataQuality={dataQuality} visibility={visibility} />
            <br />
            <Link className={css.pending} href={`/submissions?label=${encodeURIComponent(name).replace(/%20/g, '+')}`}>
                <Trans>{pendingSubmissionsCount} submissions pending</Trans>
            </Link>
        </Section>
    )
}

type Props = {
    discogsId: number
}

export function LabelHeader(props: Props): ReactElement {
    const { discogsId } = props

    return (
        <div className={css.labelHeader}>
            <h2>
                <Trans>Label</Trans>
            </h2>
            <ShortCode type={IdentifierType.Label} discogsId={discogsId} />
        </div>
    )
}
