import * as React from 'react'
import { match } from 'react-router'
import { Location } from 'history'

import { useDiscogsId } from '../../lib/use-discogs-id'
import { useLabelAllImagesQuery } from '../../api/types'
import { Gallery } from '../../components/image-gallery'

type Props = {
    match: match<{
        imageId: string
        discogsId: string
    }>
    location: Location
}

export default function ImageGallery(props: Props): React.ReactElement {
    const { match, location } = props
    const { imageId } = match.params
    const discogsId = useDiscogsId(match.params.discogsId)

    const base = match.url.replace(/\/image\/.*$/, '')
    const editUrl = `${base}/images/update`

    const { data, loading, error } = useLabelAllImagesQuery({
        ssr: true,
        variables: {
            discogsId,
        },
    })

    return (
        <Gallery
            name={data?.label?.name}
            open
            loading={loading}
            error={error}
            imageId={imageId}
            images={data?.label?.images}
            editUrl={editUrl}
            makeUrl={function (imageId: string | null, isPerma: boolean = false): Location {
                if (!imageId) {
                    return {
                        ...location,
                        search: isPerma ? '' : location.search,
                        pathname: base,
                    }
                }
                return {
                    ...location,
                    search: isPerma ? '' : location.search,
                    pathname: `${base}/image/${imageId}`,
                }
            }}
        />
    )
}
