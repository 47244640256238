import React, { ReactElement, useState } from 'react'
import { Route, match, useHistory } from 'react-router'
import { Trans } from '@lingui/macro'
import css from './styles.css'
import { useInitialLabelDataQuery, useUserLabelDataQuery, ItemType } from '../../api/types'
import { useFlag } from '../../lib/flags'
import { ErrorMessage } from '../../lib/components/error'
import { PageSpinner } from '../../lib/components/page-spinner'
import { Identifier, IdentifierType } from '../../lib/components/identifier'
import { AdTop, AdBottom } from '../../lib/components/ads'

import { Content, createOrder } from '../../lib/components/content'
import { useDiscogsId } from '../../lib/use-discogs-id'
import { languages } from '../../lib/i18n'

import { LabelHeader } from '../../components/label-header'
import { LabelActions } from '../../components/label-actions'
import { LabelTitle } from '../../components/label-title'
import { LabelReleases } from '../../components/label-releases'
import { ShareButton } from '../../components/share'

import ImageGallery from './images'

import { Page } from './analytics'
import { MarketplaceStats } from '../../components/marketplace-stats'
import { ErrorSection } from '../../lib/components/error-section'
import { LabelMetaTags } from '../../components/label-meta-tags'
import { AlternateLinks } from '../../lib/i18n/alt'

import { CuratedLists } from '../../components/curated-lists'
import { ReleaseReviews } from '../../components/reviews'

import { LabelBanner } from '../../components/label-banner'

type Props = {
    match: match<{
        discogsId: string
        slug?: string
    }>
}

const { Main, Sidebar, Full } = createOrder([
    'ad',
    'header',
    'actions',
    'marketplace',
    'discography',
    'releases',
    'share',
    'spacer',
    'bottomContainer',
])

export default function LabelPage(props: Props): ReactElement {
    const { match } = props
    const discogsId = useDiscogsId(match.params.discogsId)

    return (
        <Page discogsId={discogsId}>
            <LabelMain {...props} discogsId={discogsId} />
            <Route path={`${match.path}/image/:imageId`} component={ImageGallery} />
        </Page>
    )
}

type UseHistoryStateType = {
    name: string
    url: string
}

type MainProps = {
    discogsId: number
}

function LabelMain(props: MainProps): ReactElement | null {
    const { discogsId } = props
    const [headerIsExpanded, setHeaderIsExpanded] = useState(false)
    const history = useHistory()

    const { data, loading, error } = useInitialLabelDataQuery({
        ssr: true,
        variables: { discogsId },
        fetchPolicy: process.browser && !process.isDevWatching ? 'cache-only' : 'cache-first',
        errorPolicy: 'all',
    })

    const ssrUser = useFlag('ssr_user')

    const userData = useUserLabelDataQuery({
        ssr: ssrUser,
        returnPartialData: true,
        errorPolicy: 'all',
        variables: { discogsId },
    })

    if (error?.networkError) {
        throw error.networkError
    }

    if (!data && !loading && !error) {
        // Detect a MissingFieldError caused by a bad type merge and
        // disambiguate it from regular Not Found.
        //
        // The ReleaseData query happens with a fetchPolicy of 'cache-only'
        // on the client.  This means that if there is an error in the
        // cache (a missing field on a nested type, for example), the
        // Apollo client will return no data and no error.
        // Explicitly throw here so the error gets logged.
        throw new Error('MissingFieldsError')
    }

    const doesNotExist = !loading && data && !data.label
    if (doesNotExist) {
        return (
            <ErrorMessage status={404} message={<Trans>That label does not exist or may have been deleted.</Trans>} />
        )
    }

    if (loading || !data || !data.label) {
        return <PageSpinner />
    }

    return (
        <>
            <Identifier type={IdentifierType.Release} discogsId={discogsId} />
            <LabelTitle name={data.label.name} />
            <LabelMetaTags {...data.label} />
            <AlternateLinks languages={languages} host='https://www.discogs.com' />
            <LabelBanner historyState={history.location.state as UseHistoryStateType} />
            <Content>
                <Main name='ad'>
                    <ErrorSection>
                        <AdTop />
                    </ErrorSection>
                </Main>
                <Main name='header'>
                    <ErrorSection>
                        <LabelHeader {...data.label} setIsHeaderExpanded={setHeaderIsExpanded} />
                    </ErrorSection>
                </Main>

                <Sidebar name='actions'>
                    <ErrorSection>
                        <LabelActions {...data.label} />
                    </ErrorSection>
                </Sidebar>
                <Sidebar name='marketplace'>
                    <ErrorSection>
                        <MarketplaceStats
                            itemType={ItemType.Label}
                            title={data.label.name}
                            blockedFromSale={false}
                            {...data.label}
                        />
                    </ErrorSection>
                </Sidebar>
                <Sidebar name='share'>
                    <ErrorSection>
                        <ShareButton title={data.label.name} url={`https://www.discogs.com${data.label.siteUrl}`} />
                    </ErrorSection>
                </Sidebar>

                <Full name='releases'>
                    <ErrorSection>
                        <LabelReleases {...data.label} headerIsExpanded={headerIsExpanded} />
                    </ErrorSection>
                </Full>
                <Full name='bottomContainer'>
                    <div className={css.bottomContainer}>
                        <div className={css.section70}>
                            <ErrorSection>
                                <ReleaseReviews {...data.label} discogsId={discogsId} itemType={ItemType.Label} />
                            </ErrorSection>
                        </div>
                        <div className={css.section30}>
                            <ErrorSection>
                                <CuratedLists
                                    {...data.label}
                                    discogsId={discogsId}
                                    user={userData.data?.viewer}
                                    itemType={ItemType.Label}
                                />
                            </ErrorSection>
                        </div>
                    </div>
                </Full>
            </Content>
            <ErrorSection>
                <AdBottom />
            </ErrorSection>
        </>
    )
}
